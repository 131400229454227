.abouthme {
    width: 100%;
    color: #1e1e1e;
    background: #fff;
    /* scrollbar-color: #6e00bf;
    scrollbar-width: thin; */
    margin-top: 10%;
    border-radius: 0px;
}

.abouthme :hover{
    border-color: #6e00bf;    
}

/* ::-webkit-scrollbar{
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
} */

::-webkit-scrollbar{
    width: 6px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-track{
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: #F5F5F5;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb{
    background-color: #6e00bf;
    border-radius: 8px; 
}

.ButonGuardar{
    width: 100%;
    border-radius: 0px;
}