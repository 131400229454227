body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rw-multiselect-tag {
  display: inline-table;
  color: inherit;
  padding: 0px 10px 0px 10px;
  margin-left: calc(0.279335em - 1px);
  margin-top: 0.279335em;
  margin-top: calc(0.279335em - 1px);
  height: 1.87033em;
  border-radius: 3px;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: default;
  vertical-align: bottom !important;
  text-align: center;
  overflow: hidden;
  max-width: 100%;
}

p {
  margin: 0px;
}
.scroll {
  height: 200px;
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar {
  width: 8px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ccc;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px #ccc;
}
.error-message {
  color: red;
  font-size: 14px;
}
.total-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.optionStyle {
  margin: 10px 0px;
  border-top: solid 1px #ccc;
}
.opacity:hover {
  opacity: 0.4;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.full {
  width: 100%;
  height: 100%;
}
.link {
  color: #66bbff;
  font-family: 'Roboto';
  font-weight: 400;
}
@font-face {
  font-family: 'Metropolis-Black';
  src: url(/assets/fonts/Metropolis-Black.otf);
}
@font-face {
  font-family: 'Metropolis-Bold';
  src: url(/assets/fonts/Metropolis-Bold.otf);
}
@font-face {
  font-family: 'Metropolis-Medium';
  src: url(/assets/fonts/Metropolis-Medium.otf);
}
@font-face {
  font-family: 'Roboto-Black';
  src: url(/assets/fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url(/assets/fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: url(/assets/fonts/Roboto-LightItalic.ttf);
}
